import { graphql } from "gatsby";
import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import ContributorList from "../sections/contributors/ContributorList";
import CTA from "../sections/landing/CTA";

const ContributorsPage = ({ data }) => {
  const { team, contributors, page } = data;
  return (
    <>
      <PageWrapper title="Contributors">
        <Hero title={page.frontmatter.title}>
          {page.frontmatter.description}
        </Hero>
        <ContributorList team={team.nodes} contributors={contributors.nodes} />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default ContributorsPage;

export const query = graphql`
  query ContributorsPage {
    page: markdownRemark(
      fileAbsolutePath: { regex: "/pages/contributor-page.md/" }
    ) {
      frontmatter {
        title
        description
      }
    }
    team: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/team//" } }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          title
          description
          socials {
            facebook
            instagram
            linkedin
            twitter
            website
          }
          image {
            childImageSharp {
              fixed(width: 100, height: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
    contributors: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contributors/" } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          title
          description
        }
      }
    }
  }
`;
