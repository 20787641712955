import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  background: linear-gradient(
    147.11deg,
    rgba(95, 133, 166, 0.167177) 45.95%,
    rgba(37, 76, 108, 0.0001) 86.45%
  );
`;

const Hero = ({ title, children, ...rest }) => {
  return (
    <>
      <SectionStyled
        pt={["120px!important", null, "190px!important"]}
        pb={["50px!important", null, "180px!important"]}
        {...rest}
      >
        <Container>
          <Row>
            <Col lg="9">
              <div>
                <Title variant="hero" color="secondary">
                  {title}
                </Title>
                <Text dangerouslySetInnerHTML={{ __html: children }} />
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
