import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { TeamCard } from "../landing/Team";

const ContributorCard = ({ userImg, title, children, ...rest }) => (
  <Box
    className="text-center"
    pt="15px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <div className="text-center">
      <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1} mb={3}>
        {title}
      </Title>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const ContributorList = ({ contributors, team }) => {
  return (
    <>
      {/* <!-- Team section --> */}
      <Section className="position-relative pt-5 mt-4 pb-3">
        <Container>
          <Row className="align-items-start justify-content-center">
            {team.map((item) => {
              const { title, description, socials } = item.frontmatter;
              return (
                <Col
                  sm="6"
                  md="5"
                  lg="4"
                  className="mt-3 mt-lg-4"
                  key={item.id}
                >
                  <TeamCard
                    userImg={item.frontmatter.image.childImageSharp.fixed}
                    title={title}
                    socials={socials}
                  >
                    {description}
                  </TeamCard>
                </Col>
              );
            })}
            {contributors.map((item) => {
              const { title, description } = item.frontmatter;
              return (
                <Col
                  sm="6"
                  md="5"
                  lg="4"
                  className="mt-3 mt-lg-4"
                  key={item.id}
                >
                  <ContributorCard title={title}>{description}</ContributorCard>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default ContributorList;
